.ReactGridGallery_custom-overlay {
    overflow: hidden;
    transition: 1s;
}

.footerCount_lkhc9u {
    direction: ltr;
}

#lightboxBackdrop .header_1tk9zku,
#lightboxBackdrop .footer_60scne {
    background-color: #343a40;
}

#lightboxBackdrop .footer_60scne {
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

/*.comment-img-gallery:hover .controls-btn {*/
/*    display: flex;*/
/*}*/

/*.replay-img-gallery:hover .replay-controls-btn {*/
/*    display: flex;*/
/*}*/

.comments-image-gallery * {
    text-align: right;
}

.controls-btn, .replay-controls-btn {
    display: none;
    top: 3px;
    left: 3px
}

@media (min-width: 992px) {
     #lightboxBackdrop > div {
        margin-left: -20rem !important;
    }

    .comments-image-gallery {
        height: 100%;
        max-height: 100vh;
        min-height: 30rem;
        width: 20rem;
        position: absolute;
        right: -20rem;
        left: 100%;
        top: 0;
        background: white;
        display: flex;
        flex-direction: column;
    }

    .comments-button {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .comments-image-gallery {
        display: none;
    }

    .comments-button {
        display: block !important;
    }
}