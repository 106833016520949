.FolderItem {
    /*border: 2px solid #5555559c;*/
    border-radius: 1rem;
    overflow: hidden;
    width: 12rem;
    height: 12rem;
}

.show {
    z-index: 1000;
    position: absolute;
    background-color: #fff;
    border: 1px solid #5555559c;
    padding: 2px;
    display: block;
    margin: 0;
    cursor: pointer;
    direction: ltr;
    min-width: 15rem;
}

.hide {
    display: none;
}

/*.show li:hover {*/
/*    background-color: #55555543;*/
/*}*/

/*.show li {*/
/*    list-style: none;*/
/*    padding: 0 .1rem;*/
/*}*/

/*.show ul {*/
/*    padding: 0 .1rem;*/
/*}*/

.show a {
    border: 0 !important;
    text-decoration: none;
}

.FolderItem #customMenu {

}