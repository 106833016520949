html[dir="rtl"] .MuiOutlinedInput-notchedOutline {
    padding-left: 0!important;
    padding-right: 8px;
}
html[dir="rtl"] .MuiOutlinedInput-notchedOutline > legend {
    text-align: right!important;
}
html[dir="rtl"] .MuiInputLabel-formControl {
    left: initial!important;
    right: 0;
}
html[dir="rtl"] .MuiInputLabel-shrink, html[dir="rtl"] .MuiInputLabel-root {
    transform-origin: top right!important;
}
html[dir="rtl"] .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(-12px, 10px) scale(0.75)!important;
}
html[dir="rtl"] .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(-14px, -6px) scale(0.75)!important;
}
html[dir="rtl"] .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(-14px, 12px) scale(1);
}
html[dir="rtl"] .MuiFormHelperText-contained {
    text-align: right;
}
html[dir="rtl"] .MuiTableCell-root {
    text-align: right!important;
}
html[dir="rtl"] .MuiPickersBasePicker-container{
    direction: ltr;
}
html[dir="rtl"] .MuiInputLabel-outlined{
    transform: translate(-14px, 20px) scale(1) !important;
}
html[dir="rtl"] .MuiTab-wrapper svg{
    margin: -1px 0 0 5px !important;
}
html[dir="rtl"] .MuiSelect-iconOutlined{
    left: 7px;
    right: initial!important;
}
html[dir="rtl"] .MuiSelect-outlined.MuiSelect-outlined {
    padding-left: 32px!important;
    padding-right: 14px!important;
}
/*html[dir="rtl"] * {*/
/*    text-align: right;*/
/*}*/