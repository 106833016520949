.JobItemStudy {
    border-radius: .3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 3px solid rgb(0, 172, 193);
    background-color: #26c6da;
    width: 30%;
    color: white;
    margin-bottom: 3rem;
    box-shadow: 0 0 1.5rem #00000056;
    min-width: 25rem;
}

.JobItemStudy .JobListItem {
    white-space: nowrap;
}

.JobItemStudy span {
    white-space: pre-wrap;
    height: auto;
}

.JobItemStudy .chip-default-study {
    height: auto;
}