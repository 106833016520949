.haveValue .uploader, .haveValue .openLink{
    transition: .3s all;
    position: absolute;
    opacity: 0;
    width: 49.5%;
    height: 100%;
    top: 0;
    cursor: pointer;
}
.haveValue .openLink {
    background-color: #fafafa;
    color: #bdbdbd;
    border: 2px dashed #eeeeee;
    text-align: center;
    left: 0;
}
.haveValue:hover .uploader, .haveValue:hover .openLink{
    opacity: 1;
}
.haveValue .uploader .dropzone{
    padding: 0!important;
}