@import "bootstrap.custom.min.css";

@font-face {
    font-family: 'BMitra';
    src: url('../fonts/Mitra/BMitra.eot') format('eot'),
    url('../fonts/Mitra/BMitra.ttf') format('truetype'),
    url('../fonts/Mitra/BMitra.woff') format('woff');
}

@font-face {
    font-family: 'BYekan';
    src: url('../fonts/Yekan/BYekan.eot') format('eot'),
    url('../fonts/Yekan/BYekan.ttf') format('truetype'),
    url('../fonts/Yekan/BYekan.woff') format('woff');
}

@font-face {
    font-family: 'IranYekan';
    font-weight: normal;
    src: url('../fonts/IranYekan/iranyekanwebregular.eot') format('eot'),
    url('../fonts/IranYekan/iranyekanwebregular.ttf') format('truetype'),
    url('../fonts/IranYekan/iranyekanwebregular.woff') format('woff'),
    url('../fonts/IranYekan/iranyekanwebregular.woff2') format('woff2');
}

@font-face {
    font-family: 'IranYekan';
    font-weight: bold;
    src: url('../fonts/IranYekan/iranyekanwebbold.eot') format('eot'),
    url('../fonts/IranYekan/iranyekanwebbold.woff') format('woff'),
    url('../fonts/IranYekan/iranyekanwebbold.woff2') format('woff2');
}

html[dir="rtl"] *:not(i):not(.dx-dropdowneditor-icon) {
    font-family: "IranYekan", sans-serif !important;
}

.dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable.dx-colorbox-overlay.dx-popup-inherit-height {
    direction: ltr;
}

#root > div:nth-of-type(2):not(.MuiDrawer-root) {
    z-index: 9999 !important;
}

.tox.tox-silver-sink.tox-tinymce-aux {
    display: block;
}

.text-white {
    color: white;
}

.chip-default-study {
    border-radius: .3rem !important;
}

.dir-ltr {
    direction: ltr !important;
}

.MuiTooltip-popper {
    z-index: 4000 !important;
}

