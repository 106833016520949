.IdentityItem {
    border-radius: .3rem;
    border: 2px solid #599f5c;
    background-color: #66bb6a;
    margin-bottom: 3rem;
    text-align: right;
    width: 30%;
    min-width: 23rem;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
}

.IdentityItem .chip-default-study {
    height: auto;
}

.IdentityItem span {
    white-space: pre-wrap;
    height: auto;
}