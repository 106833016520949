.PoliticalItem {
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 4px solid #53cbcd;
    background-color: #fff;
    width: 48%;
    /*color: white;*/
    margin-bottom: 3rem;
    transition: .5s;
    position: relative;
}

.PoliticalItem-hover {
    background-color: #3b818387;
    color: white;
    transform: rotateY(180deg);
}

.PoliticalItem-hover img,
.PoliticalItem-hover h4,
.PoliticalItem-hover p {
    transform: rotateY(180deg) !important;
}

.PoliticalItem * {
    transition: .5s;
}

.PoliticalItem-content {
    font-size: 1.3rem !important;
}

@media (max-width: 992px) {
    .PoliticalItem {
        width: 100%;
    }
}
