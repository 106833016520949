.MediaControlCard-class {
    width: 30% !important;
}

.MediaControlCard-class button, .MediaControlCard-class h5, .MediaControlCard-class h6 {
    color: #fff !important;
}

@media (max-width: 992px) {
    .MediaControlCard-class {
        width: 100% !important;
    }
}