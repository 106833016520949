
@media (min-width: 768px){
    .TitleBar-p > div:first-child {
        padding: 0 0 0 1rem !important;
    }
    .TitleBar-p > div:last-child {
        padding: 0 1rem 0 0 !important;
    }
}
@media (max-width: 768px){
    .TitleBar-p > div:first-child {
        padding: 5px 0 !important;
    }
    .TitleBar-p > div:last-child {
        padding: 5px 0 !important;
    }
}