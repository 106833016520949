.active-graph {
    margin-top: -2rem !important;
}

.active-graph-r {
    margin-right: .6rem !important;
}

.default-graph {
    transition: .3s;
    transition-timing-function: cubic-bezier(0.15, 0.76, 0.32, 1.5);
}

.work-graph {
    direction: ltr;
}

.work-graph svg.custom .node circle {
    fill: #F3F3FF;
    stroke: #2593B8;
    stroke-width: 1px;
}

.work-graph svg {
    padding-left: 6rem;
}

.work-graph svg > g > g:first-child > text {
    max-width: 6rem;
    margin-right: 6rem;
}

.work-graph svg.custom .node text {
    font-size: 11px;
    background-color: #444;
    fill: #000000;
    text-shadow: 0 1px 4px rgba(0, 0, 0, .7);
}

.work-graph svg.custom .node {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
}

.work-graph svg.custom path.link {
    fill: none;
    stroke: #2593B880;
    stroke-width: 1px;
}

.video-player {
    width: 100%;
    min-width: 25rem;
    border: 4px solid #ff6c53;
    max-height: 25rem;
    position: relative;
}

.video-player > div {
    width: 100%;
    max-height: 100%;
}

.video-player > div section {
    width: 100%;
    max-height: 24.5rem;
    min-height: 100%;
}

.video-player .poster > div > div {
    border-radius: 50%;
    display: flex;
    align-items: center;
    width: 5rem;
    height: 5rem;
}

.player .video-player-title > span {
    color: #fff;
    font-size: 2rem;
}

.player .video-player-title {
    background-color: #ff6c53;
}

.player {
    background-color: #ff6c53;
    width: 30%;
    border-radius: .2rem;
}
