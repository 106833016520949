.text-single-information {
    width: 100%
}

.icon-button-mini {
    background-color: white !important;
}

.icon-button-mini {
    position: absolute !important;
    top: -.30rem;
    left: 1.5rem;
    width: .2rem;
    height: .2rem;
    display: flex;
    align-items: center;
}


.icon-button-mini .MuiSvgIcon-root {
    width: .8rem;
    height: .8rem;
}

.text-field-parent{
    position: relative;
}