.chartDivDiagram {
    width: 100%;
    direction: ltr;
    transition: .3s
}

.title-study {
    font-size: 2.5rem;
}

.image-box-study {
    border: .3rem solid;
    border-radius: .5rem
}

.tileBar-study {;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent) !important;
}

.fs-3 {
    font-size: .9rem;
    word-spacing: -1px;
    color: #1eccf2;
}

.makeStyles-container-19 {
    padding: 0 !important;
}

@media (max-width: 992px) {
    .makeStyles-container-19 .MuiGrid-root {
        padding: 0 !important;
    }
}